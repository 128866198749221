import { Injectable, inject } from '@angular/core';
import { AuthorGear } from '../gears/author.gear.js';
import { AuthorsQuery, AuthorsStore } from '../states/authors/index.js';

@Injectable({ providedIn: 'root' })
export class AuthorFacade {
  authorQuery = inject(AuthorsQuery);

  authors$ = this.authorQuery.selectAll();
  constructor(private authorGear: AuthorGear, private authorStore: AuthorsStore) { }

  selectLoadingAkita() {
    return this.authorQuery.selectLoading();
  }

  setLoading(isLoading = false) {
    this.authorStore.setLoading(isLoading);
  }

  setAuthorIdActiveAkita(authorId: string) {
    return this.authorStore.setActive(authorId);
  }

  getAuthorIdActiveAkita() {
    return this.authorQuery.getActiveId();
  }

  selectEntityAuthor(id: string) {
    return this.authorQuery.selectEntity(id);
  }

  getAuthors() {
    return this.authorGear.getAuthors();
  }

  getDetailAuthor(username: string) {
    return this.authorGear.getDetailAuthor(username);
  }

  // getUserIdFromUsername(username: string) {
  //   return this.authorGear.getUserIdFromUsername(username);
  // }
}
