import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { AuthorApi } from '../apis/author.api.js';
import { AuthorsStore } from '../states/authors/index.js';
@Injectable({ providedIn: 'root' })
export class AuthorGear {
  constructor(private authorApi: AuthorApi, private authorStore: AuthorsStore) {}

  getAuthors() {
    return this.authorApi.getAuthors().pipe(
      tap((res: any) => {
        this.authorStore.set(res);
      })
    );
  }

  getDetailAuthor(username: string) {
    return this.authorApi.getDetailAuthor(username);
  }

  // getUserIdFromUsername(username: string) {
  //   return this.authorApi.getUserIdFromUsername(username);
  // }
}
