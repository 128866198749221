import { map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';

@Injectable({ providedIn: 'root' })
export class AuthorApi {
  constructor(private apollo: Apollo) { }

  getAuthors() {
    return this.apollo
      .query<any>({
        query: gql`
          query authors {
            authors(first: 10) {
              nodes {
                userId
                user {
                  username
                  userId
                  name
                  avatar
                  updatedAt
                  createdAt
                  userNid
                }
              }
            }
          }
        `,
      })
      .pipe(map((res) => res?.['data']?.['authors']?.['nodes']));
  }



  getDetailAuthor(username: string) {
    return this.apollo
      .query<any>({
        query: gql`
          query getDetailAuthor($username: String!) {
            
            mvBookAllies(condition: { username: $username, isExist: 1, published: true } ) {
              totalCount
            }

            users(condition: { username: $username }) {
              nodes {
                username
                email
                phone
                name
                avatar
                userId
                userNid
                updatedAt
                createdAt
                personal {
                  firstname
                  middlename
                  lastname
                  dob
                  bio
                  websiteAddress
                  facebookAddress
                  zaloAddress
                  gender
                  serviceObj
                  contactObj
                }
              }
            }
           
          }
        `,
        variables: {
          username,
        },
      })
      .pipe(
        map((res) => {
          // console.log('res of username?', username, res);
          const user = res?.['data']?.['users']?.['nodes']?.[0];
          const data = res?.['data']?.['mvBookAllies'];
          return {
            ...user,
            ...user?.personal,
            ...data,
          };
        })
      );
  }
  getUserIdFromUsername(username: string) {
    return this.apollo
      .query<any>({
        query: gql`
          query getUserIdFromUsername($username: String!) {
            users(condition: { username: $username }) {
              nodes {
                username
                name
                avatar
                userId
                userNid
              }
            }
          }
        `,
        variables: {
          username,
        },
      })
      .pipe(
        map((res) => {
          const user = res?.['data']?.['users']['nodes'][0];
          return {
            ...user,
          };
        })
      );
  }
}
